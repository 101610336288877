/** @jsx jsx */
import { jsx, Heading, Box } from 'theme-ui';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from './layout';
import Seo from './seo';
import { contents } from '../styles/utils';

type PageProps = {
  data: {
    page: {
      title: string;
      slug: string;
      excerpt: string;
      body: string;
    };
  };
  [key: string]: any;
};

const Page = ({ data: { page } }: PageProps) => (
  <Layout>
    <Seo title={page.title} description={page.excerpt} />
    <Heading as="h1" variant="styles.h2">
      {page.title}
    </Heading>
    <section>
      <Box sx={{ my: 5, variant: `layout.content`, ...contents }}>
        <MDXRenderer>{page.body}</MDXRenderer>
      </Box>
    </section>
  </Layout>
);

export default Page;
