/** @jsx jsx */
import { Link } from 'gatsby';
import { jsx, Box } from 'theme-ui';
import replaceSlashes from '../utils/replaceSlashes';
import useSiteMetadata from '../hooks/use-site-metadata';
import useMinimalBlogConfig from '../hooks/use-minimal-blog-config';

const HeaderTitle = () => {
  const { siteTitle } = useSiteMetadata();
  const { basePath } = useMinimalBlogConfig();

  return (
    <Link
      to={replaceSlashes(`/${basePath}`)}
      aria-label={`${siteTitle} - Back to home`}
      sx={{ color: `heading`, textDecoration: `none` }}
    >
      <Box
        sx={{
          my: 0,
          fontWeight: `medium`,
          fontSize: [2, 4],
          display: 'flex',
          alignItems: ['flex-start', 'center'],
          flexDirection: ['column', 'row'],
        }}
      >
        {siteTitle}
        {(process.env.GATSBY_APP_ENV === 'development' ||
          process.env.GATSBY_APP_ENV === 'staging') && (
          <Box
            sx={{
              my: 0,
              fontWeight: `medium`,
              fontSize: 1,
              padding: '0 10px 5px',
              backgroundColor: '#bfc5ce',
              display: 'inline-block',
              borderRadius: '5px',
              marginTop: '5px',
              marginLeft: [0, 3],
            }}
          >
            {process.env.GATSBY_APP_ENV}
          </Box>
        )}
      </Box>
    </Link>
  );
};

export default HeaderTitle;
