/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import useSiteMetadata from '../hooks/use-site-metadata';

const Footer = () => {
  const { author } = useSiteMetadata();

  return (
    <footer>
      <Box
        sx={{
          boxSizing: `border-box`,
          display: `flex`,
          justifyContent: `space-between`,
          mt: [6],
          color: `secondary`,
          a: {
            variant: `links.secondary`,
          },
          flexDirection: [`column`, `column`, `row`],
          variant: `dividers.top`,
        }}
      >
        &copy; {new Date().getFullYear()} by {author}. All rights reserved.
      </Box>
    </footer>
  );
};

export default Footer;
