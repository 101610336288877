/** @jsx jsx */
import * as React from 'react';
import { jsx, Link as TLink, Box } from 'theme-ui';
import useMinimalBlogConfig from '../hooks/use-minimal-blog-config';

const HeaderExternalLinks = () => {
  const { externalLinks } = useMinimalBlogConfig();

  return (
    <React.Fragment>
      {externalLinks && externalLinks.length > 0 && (
        <Box sx={{ 'a:not(:first-of-type)': { ml: 3 }, fontSize: [1, `18px`] }}>
          {externalLinks.map(link => (
            <TLink key={link.url} href={link.url} target="_blank">
              {link.name}
            </TLink>
          ))}
        </Box>
      )}
    </React.Fragment>
  );
};

export default HeaderExternalLinks;
