/** @jsx jsx */
import { jsx, useColorMode, Flex, Box } from 'theme-ui';
import useMinimalBlogConfig from '../hooks/use-minimal-blog-config';
import ColorModeToggle from './colormode-toggle';
import Navigation from './navigation';
import HeaderTitle from './header-title';
import HeaderExternalLinks from './header-external-links';

const Header = () => {
  const { navigation: nav } = useMinimalBlogConfig();
  const [colorMode, setColorMode] = useColorMode();
  const isDark = colorMode === `dark`;
  const toggleColorMode = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setColorMode(isDark ? `light` : `dark`);
  };

  return (
    <header>
      <Box sx={{ mb: [1, 2] }}>
        <Flex sx={{ alignItems: `center`, justifyContent: `space-between` }}>
          <HeaderTitle />
          <ColorModeToggle isDark={isDark} toggle={toggleColorMode} />
        </Flex>
        <Box
          sx={{
            boxSizing: `border-box`,
            display: `flex`,
            variant: `dividers.bottom`,
            alignItems: `center`,
            justifyContent: `space-between`,
            mt: 3,
            color: `secondary`,
            a: { color: `secondary`, ':hover': { color: `heading` } },
            flexFlow: `wrap`,
          }}
        >
          <Navigation nav={nav} />
          <HeaderExternalLinks />
        </Box>
      </Box>
    </header>
  );
};

export default Header;
